import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["menu"];

  connect() {}

  mouseEvents(e) {
    if (e.type === "mouseover") this.menuTarget.classList.add("show");
    else this.menuTarget.classList.remove("show");
  }
}
